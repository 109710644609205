<template>
  <div class="bp">
    <div class="search-area">
      <div class="left">
        <div class="select-part" @mouseenter="showSelect = true" @mouseleave="showSelect = false">
          <div class="select-show">
            {{desc}}
          </div>
          <div class="select-list" v-show="showSelect">
            <div v-for="(item, index) of selectList" class="select-item" @click="handleSelect(item)">{{item.desc}}</div>
          </div>
        </div>
        <input v-model="queryCode" type="text" placeholder="请输入" @keydown.enter="handleSearch" />
      </div>
      <div class="right" @click="handleSearch">搜索</div>
    </div>
    <div v-loading="isLoading" v-if="queryType !== 3" class="card-list">
      <template v-if="dataList.length">
        <div class="card-item" v-for="(item, index) of dataList" :key="index">
          <div class="company-part">
            <div class="avatar">
              <div class="img">
                <img :src="item.userPicture" :alt="item.userName">
              </div>
              <div class="desc">
                <p style="font-size: 20px">{{item.userName}}</p>
                <p style="font-size: 15px">区块链地址数量：{{item.walletCount}}</p>
              </div>
            </div>
          </div>
          <div class="chian-list">
            <div class="chian-item" v-for="(witem,windex) of item.wallets" :key="windex">
              <div class="chian-title">
                {{witem.walletName}}: 
                <span>{{witem.address}}</span>
              </div>
              <div class="chianw-list">
                <div class="chianw-item" @click="handleWorkClick(pitem)" v-for="(pitem, pindex) of witem.collectionList" :key="pindex">
                  <div class="chianw-img">
                    <img :src="pitem.coverFileUrl" :alt="pitem.collectionName">
                  </div>
                  <div class="title">{{pitem.collectionName}}</div>
                  <div class="people">{{pitem.roleList ? pitem.roleList.join('，') : ''}}</div>
                </div>
                <div @click="handleSeeMore(witem.walletNumber)" class="more" key="more" v-if="witem.isMore ">
                  查看更多>>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="empty" v-else>未搜索到相关的信息</div>
    </div>
    <div v-loading="isLoading" v-else class="card-list-pro-list">
      <template v-if="dataList.length">
        <div class="card-list-pro" v-for="(item, index) of dataList" :key="index">
          <div class="cover-left" @click="handleWorkClick(item)">
            <img :src="item.coverFileUrl" :alt="item.collectionName">
          </div>
          <div class="info-right">
            <div class="cp-part-item">
              <div class="ir-title" @click="handleWorkClick(item)">{{item.collectionName}}</div>
              <div class="cp-part" v-if="item.ownerName">
                <div class="cp-name">版权方：{{item.ownerName}}</div>
                <div class="cp-desc">区块链地址名称：{{item.ownerAddressName}}</div>
                <div class="cp-desc">区块链地址：{{item.ownerAddress}}</div>
              </div>
              <div class="cp-part" v-if="item.publicName">
                <div class="cp-name">版权方：{{item.publicName}}</div>
                <div class="cp-desc">区块链地址名称：{{item.publicAddressName}}</div>
                <div class="cp-desc">区块链地址：{{item.publicAddress}}</div>
              </div>
            </div>
            
          </div>
        </div>
      </template>
      <div class="empty" v-else>未搜索到相关的信息</div>
    </div>
    <!-- 分页 -->
    <div class="pagination-box" v-if="total > 0">
      <el-pagination
        :page-size="pageSize"
        layout="prev, pager, next, jumper"
        @current-change="handlePageSizeChange"
        :total="total">
      </el-pagination>
    </div>
    <Detail :collectionNumber="collectionNumber" @onClose="visible = false" v-if="visible" />
  </div>
</template>

<script>
import { blockchain, collection } from '@/api/index'
import Detail from '@/components/Detail'

export default {
  name: "blockPublic",
  data() {
    return {
      visible: false,
      collectionNumber: '',
      showSelect: false,
      queryType: 1, 
      tempType: 1,
      desc: '用户名称',
      queryCode: '',
      pageNum: 1,
      pageSize: 20,
      total: 0,
      isLoading: false,
      selectList: [
        {
          desc: '用户名称',
          type: 1
        },
        {
          desc: '产品名称/简称',
          type: 3
        },
        {
          desc: '钱包名称/地址',
          type: 2
        }
      ],
      dataList: [],
    }
  },
  components: {
    Detail
  },
  mounted() {
    this.handleSearch()
  },
  methods: {
    handleWorkClick(item) {
      console.log(item);
      this.collectionNumber = item.collectionNumber;
      this.visible = true;
    },
    handleSelect(info) {
      this.showSelect = false;
      // this.queryType = info.type;
      this.tempType = info.type
      this.desc = info.desc
      // this.dataList = [];
      this.pageNum = 1;
      this.total = 0;
      // this.handleSearch()
    },
    async handleSearch() {
      this.queryType = this.tempType
      this.pageNum = 1;
      if(this.queryType === 0) {
        this.$message.error('请选择搜索方式')
        return
      }
      let params = {
        queryType: this.queryType,
        queryCode: this.queryCode,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      if(this.queryType === 3 ) {
        this.collection(params);
      } else {
        this.blockchain(params);
      }
    },
    // 用户钱包/ 名称
    async blockchain(params) {
      this.isLoading = true;
      let response = await blockchain(params)
      this.isLoading = false;
      if(response.code === 200) {
        this.dataList = response.data.list;
        this.total = response.data.total;
      } else {
        this.$message.error(response.msg)
      }
    },
    // 产品
    async collection(params) {
      this.isLoading = true;
      let response = await collection(params)
      this.isLoading = false;
      if(response.code === 200) {
        this.dataList = response.data.list;
        this.total = response.data.total;
      } else {
        this.$message.error(response.msg)
      }
    },
    handleSeeMore(walletNumber) {
      let routeData = this.$router.resolve({
        name: 'WorksDetail',
        query: {
          walletNumber: walletNumber
        }
      });
      window.open(routeData.href, "_blank");
    },
    handlePageSizeChange(currentPage) {
      this.pageNum = currentPage;
      if(this.queryType === 0) {
        this.$message.error('请选择搜索方式')
        return
      }
      let params = {
        queryType: this.queryType,
        queryCode: this.queryCode,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      if(this.queryType === 3 ) {
        this.collection(params);
      } else {
        this.blockchain(params);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.bp{
  background: #fff;
  padding-top: 150px;
  .search-area{
    width: 928px;
    border: 1px solid #EFEFEF;
    height: 60px;
    border-radius: 30px;
    margin: 0 auto 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left{
      flex-grow: 1;
      flex-shrink: 1;
      display: flex;
      input{
        border: 0 none;
        background: transparent;
        width: 100%;
        outline: none;
      }
    }
    .right{
      flex-grow: 0;
      flex-shrink: 0;
      width: 139px;
      height: 60px;
      border-radius: 30px;
      background: #12C6FF;
      color: #fff;
      font-size: 16px;
      text-align: center;
      line-height: 60px;
      cursor: pointer;
    }
    .select-part{
      width: 193px;
      flex-grow: 0;
      flex-shrink: 0;
      position: relative;
      .select-list{
        position: absolute;
        top: 60px;
        left: 0;
        right: 0;
        font-size: 15px;
        border-radius: 0 0 20px 20px;
        overflow: hidden;
        z-index: 10;
        background: #fff;
        .select-item{
          width: 100%;
          height: 40px;
          line-height: 40px;
          text-align: center;
          cursor: pointer;
          &:hover{
            background: #FF6C19;
            color: #fff;
          }
        }
      }
    }
    .select-show{
      height: 60px;
      line-height: 60px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
  .card-list{
    margin: 0 auto;
    width: 1200px;
  }
  .card-item{
    border: 1px solid #EFEFEF;
    margin-bottom: 20px;
    padding: 20px;
    .company-part{
      margin-bottom: 40px;
      .avatar{
        position: relative;
        .desc{
          padding: 0 50px 0 140px;
          height: 97px;
          border-radius: 48px;
          color: #fff;
          background: #FF6C19;
          position: absolute;
          top: 7px;
          left: 1px;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        .img{
          border-radius: 50%;
          width: 110px;
          height: 110px;
          position: relative;
          z-index: 2;
          background: #eee;
          overflow: hidden;
          img{
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .chian-item{
      margin-bottom: 20px;
      &:last-child{
        margin-bottom: 0;
      }
      .chian-title{
        font-size: 18px;
        color: #333333;
        display: flex;
        line-height: 25px;
        height: 25px;
        margin-bottom: 20px;
        span{
          display: inline-block;
          font-size: 15px;
          color: #666666;
          margin-left: 15px;
        }
      }
      .chianw-list{
        display: flex;
        border-bottom: 1px solid #E6E6E6;
        &:last-child{
          border-bottom: 0;
        }
        .chianw-item{
          width: 140px;
          margin-right: 20px;
          text-align: center;
          cursor: pointer;
          .chianw-img{
            width: 140px;
            height: 140px;
            background: #eee;
            margin-bottom: 5px;
            img{
              width: 100%;
              height: 100%;
            }
          }
          .title{
            color: #333333;
            font-size: 14px;
          }
          .people{
            color: #999999;
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          &:last-child{
            margin-right: 0;
          }
        }
        .more{
          color: #FF6C19;
          padding: 60px 0 0 30px;
          cursor: pointer;
        }
      }
    }
  }
}
.pagination-box{
  display: flex;
  justify-content: center;
  padding: 14px 0;
}
.card-list-pro-list{
  width: 1200px;
  margin: 0 auto;
}
.card-list-pro{
  padding: 40px;
  border: 1px solid #EFEFEF;
  border-radius: 20px;
  display: flex;
  margin-bottom: 20px;
  .cover-left{
    width: 140px;
    height: 140px;
    margin-right: 37px;
    flex-grow: 0;
    flex-shrink: 0;
    cursor: pointer;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .info-right{
    font-size: 15px;
    width: 100%;
    .cp-name{
      font-weight: bold;
    }
    .cp-name,.cp-desc{
      margin-bottom: 15px;
    }
    .ir-title{
      font-size: 20px;
      margin-bottom: 20px;
      cursor: pointer;
    }
  }
  .cp-part-item{
    border-bottom: 1px solid #E6E6E6;
    &:last-child{
      border-bottom: 0;
    }
  }
}
.card-list-pro-list, .card-list{
  min-height: 70vh;
}
.empty {
  text-align: center;
  margin-top: 100px;
  color: #999;
}
</style>